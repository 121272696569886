<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:col-6 md:offset-3">
        <div class="contact-data bg-white p-8 md:p-12">
          <h2 class="text-center mb-4 md:mb-8" v-html="payload.titel" />
          <div class="contact-text text-center pb-4 md:pb-12 mb-8 md:mb-16" v-html="payload.text" />
          <!-- contact form -->
          <form
            ref="contactForm"
            @submit.prevent="submitForm"
          >
            <input value="Kontaktformular" name="form" type="hidden">
            <input :value="locale" name="language" type="hidden">
            <!-- 1st row -->
            <div class="flex row-12 gutter-8 mb-8">
              <div class="md:col-3 flex flex-col">
                <label for="anrede">{{ payload.anrede }}</label>
                <div class="select-wrapper relative">
                  <select id="anrede" v-model="anrede" name="anrede">
                    <option selected value="Herr">
                      Herr
                    </option>
                    <option value="Frau">
                      Frau
                    </option>
                    <option value="andere">
                      Andere
                    </option>
                  </select>
                </div>
              </div>
              <div class="md:col-9 flex flex-col">
                <label for="name">{{ payload.name }}</label>
                <input
                  id="name"
                  v-model="name"
                  name="first_name"
                  type="text"
                  required
                >
              </div>
            </div>
            <!-- 2nd row -->
            <div class="flex row-12 gutter-8 mb-8">
              <div class="md:col-8 flex flex-col">
                <label for="email">{{ payload.email }}</label>
                <input
                  id="email"
                  v-model="email"
                  name="email"
                  type="email"
                  required
                >
              </div>
              <div class="md:col-4 flex flex-col">
                <label for="phone">{{ payload.telefon }}</label>
                <input
                  id="phone"
                  v-model="phone"
                  name="phone"
                  type="tel"
                >
              </div>
            </div>
            <!-- 3rd row -->
            <div class="row-12 gutter-8 flex mb-8">
              <div class="col-12 flex flex-col">
                <label for="message">{{ payload.nachricht }}</label>
                <textarea
                  id="message"
                  v-model="message"
                  name="message"
                  type="text"
                />
              </div>
            </div>
            <button type="submit" class="util-btn">
              {{ payload.senden }}
            </button>
          </form>
          <div v-if="messageSent" ref="responseText" class="response-text mt-12 text-center text-blue" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ChevronDown from '../../assets/images/chevron.svg';

export default {
  components: {
    ChevronDown,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      anrede: '',
      name: '',
      email: '',
      phone: '',
      message: '',
      messageSent: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.router.locale;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
  methods: {
    async submitForm() {
      const { contactForm } = this.$refs;
      try {
        const response = await fetch(
          `${this.$store.state.router.base}/${this.locale}/ajax/forms/new_request/notify`,
          {
            method: 'POST',
            body: new FormData(contactForm),
            // fetch erkennt content type selber - wenn ich ihn trotzdem im header mitschicke geht's nicht
            // headers: { 'Content-Type': 'multipart/form-data' },
            credentials: 'include',
          },
        );
        const json = await response.json();
        this.messageSent = true;
        this.$nextTick(() => {
          // this.$refs.responseText.innerHTML = json.message;
          if (this.locale === 'de') {
            this.$refs.responseText.textContent = 'Vielen Dank für Ihre Kontaktanfrage';
          } else if (this.locale === 'fr') {
            this.$refs.responseText.textContent = 'Merci de votre message';
          }
        });
        contactForm.reset();
      } catch (e) {
        console.error(e);
        // alert("Interner Fehler, bitte kontaktiere uns per Mail.")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-text {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 2px;
    width: px(75);
    transform: translateX(-50%);
    background-color: var(--color-light-blue);
  }
}

textarea {
  height: px(250);
}

.util-btn {
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
}

// .response-text {
//   font-size: 1.25rem;
// }
</style>
